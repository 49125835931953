import type { BindingFn, Blot, DeltaAttributes, DeltaInsertAttributes, KeyboardContext, List, MountedEditor, Numbered, Paragraph, Table, TableCell } from '@avvoka/editor'
import {
  ascendantBlot,
  ascendantLineBlot,
  AttributeMap,
  blotList,
  bubbleFormats,
  BubbleFormatsMode,
  Delta,
  DeltaTools,
  descendantBlots,
  extractLineFormats,
  getTableIndexedTable,
  getTableLayoutIndexOf,
  hasAttributes,
  isInsert,
  isScopeExactLineBlot,
  isScopeTextBlot,
  isScopeVoidBlot,
  NEWLINE_LENGTH,
  Query,
  SelectionRange,
  TableUtils
} from '@avvoka/editor'
import { BitArray, clone, Key, orderObjectKeys, Source, TextTools } from '@avvoka/shared'
import { CompareUtils } from '../../../javascripts/editors/compare'
import { stopAndPrevent } from '../../_abstract/utils/event'
import LinkUtils from '../../external_links'
import Utils from '../../utils'
import { Dialogs } from '../dialogs'
import { addColumn, addRow, addTable, removeColumn, removeRow, removeTable } from './toolbar.utils'
import { Tab } from './utils'

export const getWordAtDelta = (delta: Delta, index: number): [number, string] => {
  const text = delta.ops.map((op) => (isInsert(op) && typeof op.insert === 'string' ? op.insert : '')).join('')
  let startBound: number = index
  while (startBound > 0) {
    const char = text[--startBound]
    if (char === ' ' || !char) {
      break
    }
  }

  if (startBound !== 0) startBound++

  let endBound = startBound
  while (endBound < text.length) {
    const char = text[++endBound]
    if (char === ' ' || !char) {
      break
    }
  }
  return [startBound, text.slice(startBound, endBound)]
}

type Context = KeyboardContext

export class Toolbar {
  constructor(private readonly editor: MountedEditor) {
    const validSelectionOnly = (fn: BindingFn): BindingFn => {
      return (name, ctx, ...args) => {
        if (ctx.selection) return fn(name, ctx, ...args)
        return false
      }
    }

    editor.keyboard.addBinding(Key.B, {
      shortKey: true,
      handler: validSelectionOnly(this.bold.bind(this)),
      blotName: 'bold'
    })
    editor.keyboard.addBinding(Key.U, {
      shortKey: true,
      handler: validSelectionOnly(this.underline.bind(this)),
      blotName: 'underline'
    })
    editor.keyboard.addBinding(Key.I, {
      shortKey: true,
      handler: validSelectionOnly(this.italic.bind(this)),
      blotName: 'italic'
    })
    editor.keyboard.addBinding(Key.Z, {
      shortKey: true,
      handler: validSelectionOnly(this.undo.bind(this)),
      blotName: 'undo'
    })
    editor.keyboard.addBinding(Key.Y, {
      shortKey: true,
      handler: validSelectionOnly(this.redo.bind(this)),
      blotName: 'redo'
    })
    editor.keyboard.addBinding(Key.F, {
      shortKey: true,
      handler: validSelectionOnly(this.search.bind(this)),
      blotName: 'search'
    })
    editor.keyboard.addBinding(Key.L, {
      shortKey: true,
      handler: validSelectionOnly(this.alignLeft.bind(this)),
      blotName: 'alignLeft'
    })
    editor.keyboard.addBinding(Key.Q, {
      shortKey: true,
      handler: validSelectionOnly(this.alignRight.bind(this)),
      blotName: 'alignRight'
    })
    editor.keyboard.addBinding(Key.G, {
      shortKey: true,
      handler: validSelectionOnly(this.image.bind(this)),
      blotName: 'image'
    })
    editor.keyboard.addBinding(Key.K, {
      shortKey: true,
      handler: validSelectionOnly(this.link.bind(this)),
      blotName: 'link'
    })
    editor.keyboard.addBinding(Key.Enter, {
      shortKey: true,
      handler: validSelectionOnly(this.pagebreak.bind(this)),
      blotName: 'pagebreak'
    })
    editor.keyboard.addBinding(Key.NumpadEnter, {
      shortKey: true,
      handler: validSelectionOnly(this.pagebreak.bind(this)),
      blotName: 'pagebreak'
    })
    editor.keyboard.addBinding(Key.E, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.alignCenter.bind(this)),
      blotName: 'alignCenter'
    })
    editor.keyboard.addBinding(Key.J, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.alignJustify.bind(this)),
      blotName: 'alignJustify'
    })
    editor.keyboard.addBinding(Key.K, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.list.bind(this)),
      blotName: 'list'
    })
    editor.keyboard.addBinding(Key.M, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.numbered.bind(this)),
      blotName: 'numbered'
    })
    editor.keyboard.addBinding(Key.G, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.subscript.bind(this)),
      blotName: 'subscript'
    })
    editor.keyboard.addBinding(Key.P, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.superscript.bind(this)),
      blotName: 'superscript'
    })
    editor.keyboard.addBinding(Key.F, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.footnote.bind(this)),
      blotName: 'footnote'
    })
    editor.keyboard.addBinding(Key.R, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.crossref.bind(this)),
      blotName: 'crossref'
    })
    editor.keyboard.addBinding(Key.C, {
      shortKey: true,
      shiftKey: true,
      handler: validSelectionOnly(this.tableOfContents.bind(this)),
      blotName: 'tableOfContents'
    })

    if (editor.options.mode !== 'document') {
      editor.keyboard.addBinding(Key.Y, {
        shortKey: true,
        shiftKey: true,
        handler: validSelectionOnly(this.repeater.bind(this)),
        blotName: 'repeater'
      })
      editor.keyboard.addBinding(Key.P, {
        altKey: true,
        handler: validSelectionOnly(this.placeholder.bind(this)),
        blotName: 'placeholder'
      })
      editor.keyboard.addBinding(Key.B, {
        altKey: true,
        handler: validSelectionOnly(this.condition.bind(this)),
        blotName: 'condition'
      })
      editor.keyboard.addBinding(Key.I, {
        altKey: true,
        handler: validSelectionOnly(this.icondition.bind(this)),
        blotName: 'icondition'
      })
    }

    editor.scroll.node.addEventListener('click', this.onClickInContainer)
    editor.scroll.node.addEventListener('mousedown', this.onMouseDownInContainer)
  }

  private readonly onClickInContainer = (e: MouseEvent) => {
    if (e.target != null) {
      const blot = this.editor.registry.getFromNode(e.target as Node)
      if (blot) {
        this.onClickBlot(e, blot)
      }
    }
  }

  private readonly onMouseDownInContainer = (e: MouseEvent) => {
    if (e.target != null) {
      const blot = this.editor.registry.getFromNode(e.target as Node)
      if (blot) {
        this.onMouseDownBlot(e, blot)
      }
    }
  }

  process<FnName extends keyof this>(functionName: FnName, ...args: unknown[]) {
    const method = this[functionName]
    if (typeof method === 'function') {
      method.apply(this, [functionName, ...args])
    } else {
      throw new Error(`Toolbar: Function ${String(functionName)} doesn't exist.`)
    }
  }

  processDialog(name: string, dialogName: keyof typeof Dialogs, requiresCollapsed: boolean, processor: (this: Toolbar, name: string, value: DeltaAttributes) => boolean) {
    const selection = this.editor.selection.validValue
    if (selection) {
      if ((requiresCollapsed && selection.isCollapsed) || !requiresCollapsed) {
        if (ascendantBlot(selection.blotAtStart, (blot) => blot.statics.blotName === name) == null) EditorFactory.dialogs[this.editor.options.id!](dialogName)
        else processor.call(this, name, { [name]: null })
      }
      return true
    }
    return false
  }

  processInlineDialog(name: string, dialogName: keyof typeof Dialogs, requiresCollapsed = true) {
    // Check if we have the following format active and remove it
    const found = this.editor.getDelta(this.editor.selection.validValue!).ops.some((op) => hasAttributes(op) && op.attributes[name] !== undefined)

    if (found) {
      return this.processInline(name, { [name]: null }, false)
    }

    return this.processDialog(name, dialogName, requiresCollapsed, this.processInline.bind(this))
  }

  processEmbedDialog(name: string, dialogName: keyof typeof Dialogs, requiresCollapsed = true) {
    const selection = this.editor.selection.validValue
    if (selection && selection.isCollapsed) {
      const format = selection.blotAtStart?.statics?.blotName === name
      if (format) {
        const updateDelta = new Delta().retain(selection.start).delete(1)
        this.editor.update(updateDelta, this.getUpdateSource())
        return true
      }
    }

    return this.processDialog(name, dialogName, requiresCollapsed, this.processEmbed.bind(this))
  }

  processLineDialog(name: string, dialogName: keyof typeof Dialogs) {
    return this.processDialog(name, dialogName, false, this.processLine.bind(this))
  }

  getUpdateSource() {
    return new BitArray().set(Source.USER).set(Source.TOOLBAR)
  }

  processInline(name: string, value: DeltaAttributes, invert = true): boolean {
    const selection = this.editor.selection.validValue
    if (selection) {
      if (selection.isCollapsed) {
        if (isScopeTextBlot(selection.blotAtStart)) {
          const format = ascendantBlot(selection.blotAtStart, (b) => b.statics.blotName === name)
          if (format) {
            let updateDelta
            if (invert) updateDelta = new Delta().retain(format.index()).retain(format.length(), { [name]: null })
            else updateDelta = new Delta().retain(format.index()).retain(format.length(), value)

            // Disables tracking-changes for inlines
            this.editor.update(updateDelta, this.getUpdateSource().set(Source.TRACKING_CHANGES))
          } else {
            const line = ascendantLineBlot(selection.blotAtStart)!
            if (!line) return false
            const offset = selection.start - line.index()
            const [wordOffset, word] = getWordAtDelta(line.getDelta(), offset)
            const updateDelta = new Delta().retain(selection.start - offset + wordOffset).retain(word.length, value)
            // Disables tracking-changes for inlines
            this.editor.update(updateDelta, this.getUpdateSource().set(Source.TRACKING_CHANGES))
          }
        }
      } else {
        const found = this.editor.getDelta(selection).ops.some(
          (op) => hasAttributes(op) && op.attributes[name] !== undefined
          // && equal(op.attributes[name], value[name]) // comparing {placeholder:{}} against {placeholder:{'data-repeater-id': 'abcd'}} == (false) // placeholder in loop and doing undo
        )
        const updateDelta = new Delta().retain(selection.start).retain(selection.length, found && invert ? { [name]: null } : value)
        this.editor.update(updateDelta, this.getUpdateSource().set(Source.TRACKING_CHANGES))
      }

      return true
    }
    return false
  }

  processEmbed(name: string, value: DeltaInsertAttributes): boolean {
    const selection = this.editor.selection.validValue
    if (selection) {
      if (selection.isCollapsed && (!isScopeVoidBlot(selection.blotAtStart) || selection.blotAtStart.statics.blotName === 'break')) {
        this.editor.update(new Delta().retain(selection.start).insert(value), this.getUpdateSource())
      } else {
        const embed = ascendantBlot(selection.blotAtStart, (b) => b.statics.blotName === name, true)
        if (embed) {
          const updateDelta = new Delta().retain(embed.index()).delete(embed.length())
          this.editor.update(updateDelta, this.getUpdateSource())
        }
      }
      return true
    }
    return false
  }

  processLine(
    name: string,
    value: DeltaInsertAttributes[string] | string,
    toNullFn = (line: Blot): DeltaAttributes => {
      return { [name]: null }
    },
    toValueFn = (line: Blot): DeltaAttributes => {
      return { [name]: value } as DeltaAttributes
    }
  ): boolean {
    const selection = this.editor.selection.validValue
    if (selection) {
      if (selection.isCollapsed) {
        let updateDelta
        const format = ascendantBlot(selection.blotAtStart, (b) => b.statics.blotName === name)
        if (format) {
          const line = ascendantLineBlot(selection.blotAtStart, true)!
          updateDelta = new Delta().retain(format.index()).retain(format.length(), toNullFn(line))
        } else {
          const line = ascendantLineBlot(selection.blotAtStart, true)!
          // In collapse mode we want to apply the format as last in order
          updateDelta = new Delta().retain(line.index() + line.length() - NEWLINE_LENGTH).retain(
            NEWLINE_LENGTH,
            AttributeMap.compose(
              {
                ...line
                  .path()
                  .slice(0, -1)
                  .reduce<DeltaAttributes>((acc, item) => ((acc[item.statics.blotName] = {}), acc), {})
              },
              toValueFn(line)
            )
          )
        }

        this.editor.update(updateDelta, this.getUpdateSource())
      } else {
        const lines = selection.getLines().filter(isScopeExactLineBlot)
        const format = lines.some(() => ascendantBlot(selection.blotAtStart, (b) => b.statics.blotName === name) != null)

        // If we have selected multiple lines, we want to wrap content into the new added format
        const wrapContent = lines.length > 1

        const updateDelta = DeltaTools.forEach(lines, (line) => {
          const newFormat = toValueFn(line)
          let formats = AttributeMap.compose(
            {
              ...line
                .path()
                .slice(0, -1)
                .reduce<DeltaAttributes>((acc, item) => ((acc[item.statics.blotName] = {}), acc), {})
            },
            newFormat
          )
          if (wrapContent) {
            const keys = Object.keys(newFormat).concat(
              line
                .path()
                .slice(0, -1)
                .map((b) => b.statics.blotName)
            )
            formats = orderObjectKeys(formats, keys)
          }

          return [line.index(), line.length(), new Delta().retain(line.length() - NEWLINE_LENGTH).retain(NEWLINE_LENGTH, format ? toNullFn(line) : formats)]
        })
        this.editor.update(updateDelta, this.getUpdateSource())
      }
      return true
    }
    return false
  }

  onMouseDownBlot(e: MouseEvent, blot: Blot) {
    if (blot.statics.blotName === 'resizer' && e.button === 0) {
      e.preventDefault()

      const cell = ascendantBlot(blot, (b) => b.statics.blotName === 'td') as TableCell
      const table = ascendantBlot(cell, (b) => b.statics.blotName === 'table') as Table

      // We need to reset resizer for each cell in the column
      const indexedTable = getTableIndexedTable(table)
      const { cellIndex } = getTableLayoutIndexOf(table, cell, indexedTable)
      for (let i = 0; i < indexedTable.length; i++) {
        const row = indexedTable[i]
        const cell = row[cellIndex]
        cell.node.removeAttribute('width')
      }

      // Disable observer to avoid unnecessary updates
      this.editor.readonly = true

      let newWidth = e.clientX - cell.node.getBoundingClientRect().left
      cell.node.style.width = `${newWidth}px`

      // Listen to mouse move
      const onMouseMove = (e: MouseEvent) => {
        // Update the 'width' style of the cell
        newWidth = e.clientX - cell.node.getBoundingClientRect().left
        cell.node.style.width = `${newWidth}px`
      }
      document.addEventListener('mousemove', onMouseMove)

      // Listen to mouse up
      const onMouseUp = () => {
        // Remove the 'width' style of the cell
        cell.node.style.width = ''

        this.editor.readonly = false

        // Remove listener
        document.removeEventListener('mousemove', onMouseMove)

        // Set the width of the column, we need to set it on each cell in the column
        const updateDelta = DeltaTools.forEach(indexedTable, (row) => {
          const cell = row[cellIndex]
          const lines = descendantBlots(cell, 0, cell.length(), isScopeExactLineBlot)
          const cellPosition = cell.index()
          return [
            cellPosition,
            cell.length(),
            DeltaTools.forEach(lines, (line) => {
              return [line.index() - cellPosition, line.length(), new Delta().retain(line.length() - NEWLINE_LENGTH).retain(NEWLINE_LENGTH, { td: { width: `${newWidth}px` } })]
            })
          ]
        })

        this.editor.update(updateDelta, BitArray.fromSource(Source.USER))
      }
      document.addEventListener('mouseup', onMouseUp, { once: true })
    }
  }

  onClickBlot(e: MouseEvent, blot: Blot) {
    if (blot.statics.blotName === 'crossref' && e.button === 0) {
      if (this.editor.options.mode === 'document' || e.ctrlKey || e.metaKey) {
        e.preventDefault()
        const referenceId = blot.attributes['data-reference-id']
        const block = this.editor.query(Query<Blot>(...blotList.filter(isScopeExactLineBlot).map((b) => b.blotName)).WithExactAttributes({ 'data-uuid': referenceId }))
        if (block.length) {
          Utils.scrollNodeIntoView(block[0].node as HTMLElement, this.editor.scroll.node)
          Utils.highlightNode(block[0].node!, 3000)
        }
      }
    }

    if (blot.statics.blotName === 'avvTocEntry') {
      if (this.editor.options.mode === 'document' || e.ctrlKey || e.metaKey) {
        const referenceId = blot.attributes['data-block-uuid']
        const block = this.editor.query(Query<Blot>(...blotList.filter(isScopeExactLineBlot).map((b) => b.blotName)).WithExactAttributes({ 'data-uuid': referenceId }))
        if (block.length) {
          Utils.scrollNodeIntoView(block[0].node as HTMLElement, this.editor.scroll.node)
          Utils.highlightNode(block[0].node!, 3000)
        }
      }
    }

    const link = ascendantBlot(blot, (blot) => blot.statics.blotName === 'link', true)
    if (link != null && e.button === 0) {
      if (e.ctrlKey || e.metaKey) LinkUtils.openLink(link.attributes.href, e, true)
      else stopAndPrevent(e)
    }

    if (isScopeVoidBlot(blot) && e.button === 0) {
      this.editor.selection.setSelection(SelectionRange.fromBlot(blot), new BitArray().set(Source.USER))
    }
  }

  /** EMBEDS */

  image(name: string) {
    return this.processEmbedDialog(name, 'image')
  }

  crossref(name: string) {
    return this.processEmbedDialog(name, 'crossref')
  }

  footnote(name: string) {
    return this.processEmbedDialog(name, 'footnote')
  }

  formula(name: string) {
    return this.processEmbedDialog(name, 'formula')
  }

  signature(name: string) {
    return this.processEmbedDialog(name, 'signature')
  }

  sysdate(name: string) {
    return this.processEmbedDialog(name, 'sysdate')
  }

  sysattribute(name: string) {
    return this.processEmbedDialog(name, 'sysattribute')
  }

  avvPageNumber(name: string) {
    return this.processEmbed(name, { avvPageNumber: {} })
  }

  /** INLINES */

  bold(name: string): boolean {
    return this.processInline(name, { bold: {} })
  }

  italic(name: string) {
    return this.processInline(name, { italic: {} })
  }

  underline(name: string) {
    return this.processInline(name, { underline: {} })
  }

  superscript() {
    return this.processInline('sup', { sup: {} })
  }

  subscript() {
    return this.processInline('sub', { sub: {} })
  }

  placeholder(name: string) {
    return this.processInline(name, { placeholder: {} })
  }

  fontSize(name: string, ctx: Context, fontSize?: number) {
    if (fontSize == null) return
    return this.processInline(name, { fontSize: fontSize == null ? null : { size: String(fontSize) } }, false)
  }

  font(name: string, ctx: Context, font?: string) {
    if (font == null) return
    return this.processInline(name, { font: font == null ? null : { font } }, false)
  }

  color(name: string, ctx: Context, hexColor?: string) {
    if (hexColor == null) return
    return this.processInline(name, { color: hexColor == null ? null : { 'data-value': hexColor } }, false)
  }

  quickOperation(name: string) {
    return this.processInlineDialog(name, 'matholder')
  }

  icondition(name: string) {
    return this.processInlineDialog(name, 'icondition', false)
  }

  link(name: string) {
    return this.processInlineDialog(name, 'link', false)
  }

  /** LINES */

  alignLeft() {
    return this.processLine(
      'avv-align',
      'left',
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': null } }
      },
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': 'left' } }
      }
    )
  }

  alignCenter() {
    return this.processLine(
      'avv-align',
      'center',
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': null } }
      },
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': 'center' } }
      }
    )
  }

  alignRight() {
    return this.processLine(
      'avv-align',
      'right',
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': null } }
      },
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': 'right' } }
      }
    )
  }

  alignJustify() {
    return this.processLine(
      'avv-align',
      'justify',
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': null } }
      },
      (line) => {
        return { [line.statics.blotName]: { 'avv-align': 'justify' } }
      }
    )
  }

  dirLtr() {
    return this.processLine(
      'dir',
      'ltr',
      (line) => {
        return { [line.statics.blotName]: { dir: null } }
      },
      (line) => {
        return { [line.statics.blotName]: { dir: 'ltr' } }
      }
    )
  }

  dirRtl() {
    return this.processLine(
      'dir',
      'rtl',
      (line) => {
        return { [line.statics.blotName]: { dir: null } }
      },
      (line) => {
        return { [line.statics.blotName]: { dir: 'rtl' } }
      }
    )
  }

  list(name: string) {
    return this.processLine(name, { level: '1' })
  }

  numbered(name: string) {
    return this.processLineDialog(name, 'numbered')
  }

  condition(name: string) {
    return this.processLineDialog(name, 'condition')
  }

  avvSeparator(name: string) {
    return this.processLineDialog(name, 'avvSeparator')
  }

  clause(name: string) {
    return this.processLineDialog(name, 'clause')
  }

  pagebreak(name: string) {
    return this.processLineDialog(name, 'pagebreak')
  }

  repeater(name: string) {
    const selection = this.editor.selection.validValue
    if (selection) {
      const querySet = [Query('repeater'), Query('tr').WithAttributes('data-repeater-id')]
      const repeaters = this.editor.querySet(querySet).flat()
      const num = repeaters.length + 1
      const value = {
        repeater: {
          'data-repeater-id': TextTools.randomIdTimestamp(),
          'data-repeater-name': `Loop ${num}`,
          'data-repeater-label': `Loop label ${num}`
        }
      }
      const tr = ascendantBlot(selection.blotAtStart, (blot) => blot.statics.blotName === 'tr')
      if (tr != null) {
        const format = tr.attributes['data-repeater-id']
        const updateDelta = DeltaTools.forEach(descendantBlots(tr, 0, tr.length(), isScopeExactLineBlot), (line) => {
          return [
            line.index(),
            line.length(),
            new Delta().retain(line.length() - NEWLINE_LENGTH).retain(NEWLINE_LENGTH, {
              tr: format
                ? {
                    'data-repeater-id': null,
                    'data-repeater-name': null,
                    'data-repeater-label': null
                  }
                : value.repeater
            })
          ]
        })
        this.editor.update(updateDelta, this.getUpdateSource())
      } else {
        return this.processLine(name, value.repeater)
      }
    }
    return false
  }

  // Apply style to the whole line
  applyStyle(name: string, ctx: Context, styleName?: string) {
    const selection = this.editor.selection.validValue
    if (!selection) return false

    const styleStore = this.editor.options.styleStore ?? AvvStore.getters
    if (!styleStore) return false

    const styleDefinition = extractLineFormats(
      styleName === 'Normal' ? (clone(styleStore.defaultStyle.definition) as DeltaAttributes) : clone(styleStore.docxSettings.formats[styleName!]?.definition ?? {})
    )

    const preventChangingContainers = ['table', 'tr', 'td', 'clause', 'condition']

    const blots = this.editor.query(Query<Numbered | Paragraph>(...blotList.filter(isScopeExactLineBlot).map((b) => b.blotName), 'numbered'))

    const updateDelta = DeltaTools.forEach(selection.getLines().filter(isScopeExactLineBlot), (line) => {
      const formats = bubbleFormats(line, BubbleFormatsMode.LINE_CONTAINERS_ONLY)
      const updatedFormats = AttributeMap.compose(AttributeMap.diff(formats, styleDefinition), { [line.statics.blotName]: { 'data-avv-style': styleName! } }) as DeltaAttributes
      if (updatedFormats[line.statics.blotName]!['data-uuid'] === null) delete updatedFormats[line.statics.blotName]!['data-uuid']
      preventChangingContainers.forEach((b) => delete updatedFormats[b])

      // When we are inserting numbering, use section-id from previous numbering when exists or create a new one.
      if (updatedFormats.numbered && formats.numbered === undefined && updatedFormats.numbered['data-section-id'] === undefined) {
        let lastNumbering: Blot | null = null
        for (const blot of blots) {
          if (blot.statics.blotName === 'numbered') {
            lastNumbering = blot
          } else if (blot === line) {
            break
          }
        }

        updatedFormats.numbered['data-section-id'] = lastNumbering?.attributes['data-section-id'] ?? `section-${TextTools.randomText(6)}`
      }

      const blockFormat = line.statics.blotName
      if (updatedFormats.numbered) {
        updatedFormats.numbered['data-numbered-id'] = formats.numbered?.['data-numbered-id'] ?? `${TextTools.randomText(6)}`
        // Prevent overwriting section id when is set above
        updatedFormats.numbered['data-section-id'] ??= formats.numbered?.['data-section-id'] ?? `section-${TextTools.randomText(6)}`

        updatedFormats[blockFormat] ??= {}

        if (styleDefinition.numbered?.['data-avv-indent-left'] !== undefined) {
          updatedFormats[blockFormat]!['num-indent'] = null
        }

        if (styleDefinition.numbered?.['data-avv-padding-left'] !== undefined) {
          updatedFormats[blockFormat]!['num-padding'] = null
        }

        if (styleDefinition.numbered?.['level'] !== undefined) {
          updatedFormats[blockFormat]!['num-level'] = null
        }
      }

      return [line.index() + line.length() - NEWLINE_LENGTH, NEWLINE_LENGTH, new Delta().retain(NEWLINE_LENGTH, updatedFormats)]
    })

    if (updateDelta.ops.length > 0) {
      this.editor.update(updateDelta, this.getUpdateSource())
      return true
    }

    return false
  }

  /** DUMMY */

  undo() {
    this.editor.history.undo()
    return true
  }

  redo() {
    this.editor.history.redo()
    return true
  }

  removeRow(name: string) {
    return removeRow(name, this.editor)
  }

  removeColumn(name: string) {
    return removeColumn(name, this.editor)
  }

  removeTable(name: string) {
    return removeTable(name, this.editor)
  }

  addRow(name: string, ctx: Context, top?: boolean, rowId?: string) {
    return addRow(top!, this.editor, rowId)
  }

  addColumn(name: string, ctx: Context, left?: boolean) {
    return addColumn(left!, this.editor)
  }

  addTable(name: string, ctx: Context, rows?: number, cells?: number) {
    return addTable(name, rows!, cells!, this.editor)
  }

  addRowTop(name: string, ctx: Context) {
    return this.addRow(name, ctx, true)
  }

  addRowBottom(name: string, ctx: Context) {
    return this.addRow(name, ctx, false)
  }

  addColumnLeft(name: string, ctx: Context) {
    return this.addColumn(name, ctx, true)
  }

  addColumnRight(name: string, ctx: Context) {
    return this.addColumn(name, ctx, false)
  }

  acceptTC() {
    if (this.editor.options.mode !== 'document') return false

    window.avv_dialog({
      squareDisplay: true,
      confirmMessage: localizeText(`document.modals.tc_all_confirm.message`, {
        type: 'accept'
      }),
      confirmTitle: localizeText(`document.modals.tc_all_confirm.title`),
      confirmCallback: (val) => {
        if (val) {
          void (async () => {
            await this.editor.negotiation.asyncAcceptChanges()
          })()
        }
      }
    })

    return true
  }

  rejectTC() {
    if (this.editor.options.mode !== 'document') return false

    window.avv_dialog({
      squareDisplay: true,
      confirmMessage: localizeText(`document.modals.tc_all_confirm.message`, {
        type: 'reject'
      }),
      confirmTitle: localizeText(`document.modals.tc_all_confirm.title`),
      confirmCallback: (val) => {
        if (val) {
          void (async () => {
            await this.editor.negotiation.asyncRejectChanges()
          })()
        }
      }
    })
    return true
  }

  search() {
    document.getElementById('ds-sidebar')?.scrollIntoView({ behavior: 'smooth' })
    EditorFactory.dialogs[this.editor.options.id!]('search')
    return true
  }

  attributeLibrary() {
    EditorFactory.dialogs[this.editor.options.id!]('attributeLibrary')
    return true
  }

  operationLibrary() {
    EditorFactory.dialogs[this.editor.options.id!]('operationLibrary')
    return true
  }

  externalPlaceholder() {
    EditorFactory.dialogs[this.editor.options.id!]('externalPlaceholder')
    return true
  }

  openAI() {
    EditorFactory.dialogs[this.editor.options.id!]('openAI')
    return true
  }

  tableOfContents() {
    EditorFactory.dialogs[this.editor.options.id!]('avvToc')
    return true
  }

  save() {
    void CompareUtils.saveVersion()
    return true
  }

  tablePosition(name: string, ctx: Context, position?: string) {
    const selection = this.editor.selection.validValue
    if (selection) {
      const td = ascendantBlot(selection.blotAtStart, (blot) => blot.statics.blotName === 'td')
      if (td == null) return false
      const updateDelta = new Delta()
      let lastIndex = 0
      descendantBlots(td, 0, td.length(), isScopeExactLineBlot).forEach((line) => {
        updateDelta.retain(line.index() - lastIndex)
        updateDelta.retain(line.length() - NEWLINE_LENGTH)
        updateDelta.retain(NEWLINE_LENGTH, {
          td: { 'data-position': String(position) }
        })
        lastIndex = line.index() + line.length()
      })

      this.editor.update(updateDelta, this.getUpdateSource())
      return true
    }
    return false
  }

  colorFill(name: string, ctx: Context, hex?: string) {
    const selection = this.editor.selection.validValue
    if (selection) {
      const td = ascendantBlot(selection.blotAtStart, (blot) => blot.statics.blotName === 'td')
      if (td == null) return false

      const updateDelta = new Delta()
      let lastIndex = 0
      descendantBlots(td, 0, td.length(), isScopeExactLineBlot).forEach((line) => {
        updateDelta.retain(line.index() - lastIndex)
        updateDelta.retain(line.length() - NEWLINE_LENGTH)
        updateDelta.retain(NEWLINE_LENGTH, {
          td: { 'data-bg-color': String(hex) }
        })
        lastIndex = line.index() + line.length()
      })

      this.editor.update(updateDelta, this.getUpdateSource())
      return true
    }
    return false
  }

  mergeCells(name: string, ctx: Context, direction?: 'top' | 'left' | 'right' | 'bottom') {
    const selection = this.editor.selection.validValue
    if (selection) {
      const td = ascendantBlot(selection.blotAtStart, (blot) => blot.statics.blotName === 'td') as TableCell
      if (td == null) return false
      TableUtils.merge(td, direction!, this.editor)
      return true
    }
    return false
  }

  indentIncrease() {
    const selection = this.editor.selection.validValue
    if (selection) {
      const lastNumbered: Numbered | null = null
      const lastList: List | null = null
      const lists = new Set<Numbered | List>()
      const nonLists = new Set<Paragraph>()
      const lines = selection.getLines().filter(isScopeExactLineBlot) as Paragraph[]
      lines.forEach((line) => {
        const numbered = ascendantBlot(line, (b) => b.statics.blotName === 'numbered')
        if (lastNumbered != numbered && numbered != null) {
          lists.add(numbered as Numbered)
        }

        const list = ascendantBlot(line, (b) => b.statics.blotName === 'list')
        if (lastList != list && list != null) {
          lists.add(list as List)
        }

        if (numbered == null && list == null) {
          nonLists.add(line)
        }
      })

      // increase numbering/list level
      if (lists.size > 0 && nonLists.size <= 0) {
        lists.forEach((list) => {
          const level = +(list.attributes['level'] ?? 1)
          if (list.hasNextLevel()) list.attributes.level = String(level + 1)
        })
      } else {
        // increase padding
        lines.forEach((line) => {
          line.attributes['data-avv-padding-left'] = String(Math.max((+line.attributes['data-avv-padding-left'] || 0) + 3, 0))
        })
      }
      return true
    }
    return false
  }

  indentDecrease() {
    const selection = this.editor.selection.validValue
    if (selection) {
      const lastNumbered: Numbered | null = null
      const lastList: List | null = null
      const lists = new Set<Numbered | List>()
      const nonLists = new Set<Paragraph>()
      const lines = selection.getLines().filter(isScopeExactLineBlot) as Paragraph[]
      lines.forEach((line) => {
        const numbered = ascendantBlot(line, (b) => b.statics.blotName === 'numbered')
        if (lastNumbered != numbered && numbered != null) {
          lists.add(numbered as Numbered)
        }

        const list = ascendantBlot(line, (b) => b.statics.blotName === 'list')
        if (lastList != list && list != null) {
          lists.add(list as List)
        }

        if (numbered == null && list == null) {
          nonLists.add(line)
        }
      })

      // decrease numbering/list level
      if (lists.size > 0 && nonLists.size <= 0) {
        lists.forEach((list) => {
          const level = +(list.attributes.level ?? 1)
          if (level > 1) list.attributes.level = String(level - 1)
        })
      } else {
        // increase indent
        lines.forEach((line) => {
          line.attributes['data-avv-padding-left'] = String(Math.max((+line.attributes['data-avv-padding-left'] || 0) - 3, 0))
        })
      }
      return true
    }
    return false
  }

  replaceStyles() {
    EditorFactory.dialogs[this.editor.options.id!]('replaceStyles')
    return false
  }

  manageStyles() {
    EditorFactory.dialogs[this.editor.options.id!]('styles')
    return false
  }
}

// each format is separated via space, (?)question-mark is for onboarder only, (!)exclamation mark is for nego only, <-> is flex, | is separator
export const DefaultTabs = {
  [Tab.Home]:
    'undo redo | font | fontSize | bold underline italic subscript superscript color | alignLeft alignCenter alignRight alignJustify dirLtr dirRtl | indentIncrease indentDecrease | numbered list | search <-> | ?placeholder ?icondition ?condition !openAI !save !acceptTC !rejectTC',
  [Tab.Insert]:
    'undo redo | table addRowBottom addRowTop addColumnLeft addColumnRight | removeRow removeColumn | tableMerge tablePosition tableColor | link image | tableOfContents crossref footnote avvPageNumber | pagebreak | !clause',
  [Tab.Review]: 'undo redo | toggleCommentsVisibility | acceptTC rejectTC toggleTCVisibility !toggleTaggedClauseVisibility save',
  [Tab.Styles]: '',
  [Tab.Automation]:
    'undo redo | placeholder icondition condition repeater quickOperation | sysdate signature ?externalPlaceholder avvSeparator formula | search | clause attributeLibrary operationLibrary <-> openAI'
}

export const withoutTabs = (tabsToRemove: string[], tabs: Record<string, string>): Record<string, string> => {
  tabsToRemove.forEach((tab) => {
    delete tabs[tab]
  })

  return tabs
}

export const withoutIcons = (iconsToRemove: string[], tabs: Record<string, string>): Record<string, string> => {
  for (const key in tabs) {
    let tabFormats = tabs[key]
    iconsToRemove.forEach((icon) => {
      const regExp = new RegExp(String.raw`\s[!?]?${icon}`, 'g')
      tabFormats = tabFormats.replace(regExp, '')
    })
    tabs[key] = tabFormats
  }

  return tabs
}

export const defineTabs = (iconsToRemove: string[], tabsToRemove: string[], tabs: Record<string, string>) => {
  return { tabs: withoutIcons(iconsToRemove, withoutTabs(tabsToRemove, tabs)) }
}
