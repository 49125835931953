import { useUserStore } from '@stores/generic/user.store'
import { getActivePinia } from 'pinia'
export default class LinkUtils {
  static handleExternalLinkDialog(link: string) {
    const dialog = document.getElementById('external-link-modal') as HTMLElement
    const urlDisplay = document.getElementById('external-link-display')
    if (dialog) {
      dialog.classList.remove('hidden')
      dialog.style.display = 'block'
    } else {
      console.warn('External link dialog not found')
    }
    if (urlDisplay) urlDisplay.textContent = link
  }

  static openLink(link: string, event?: Event, newTab?: boolean) {
    event?.preventDefault()
    if (LinkUtils.isExternalLink(link)) {
      this.handleExternalLinkDialog(link)
    } else if (!newTab) {
      window.location.href = link
    } else {
      window.open(link, '_blank')
    }
  }

  static isExternalLink(link: string): boolean {
    try {
      const currentHostname = window.location.hostname
      const linkHostname = new URL(link).hostname
      if (currentHostname === linkHostname) return false
      const urlWhitelist = useUserStore(getActivePinia()).urlWhitelist
      return (
        currentHostname !== linkHostname &&
        !urlWhitelist.some(
          (w) => w.url.replace('www.', '') === linkHostname.replace('www.', '')
        )
      )
    } catch {
      return false
    }
  }

  static isLinkTag(element: EventTarget | null): element is HTMLAnchorElement {
    if (!element) return false
    return 'tagName' in element && element.tagName === 'A'
  }

  static hasLinkHref(element: HTMLAnchorElement | null) {
    if (!element) return false
    return (
      'href' in element && element.href !== '' && !element.href.startsWith('#')
    )
  }
}
